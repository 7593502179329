import * as XLSX from 'xlsx';
import Papa from 'papaparse';

function stringToArrayBuffer(str) {
  const buffer = new ArrayBuffer(str.length);
  const uintArray = new Uint8Array(buffer);

  for (let i = 0; i < str.length; i += 1) {
    uintArray[i] = str.charCodeAt(i);
  }

  return buffer;
}

function triggerFileDownload(fileBlob, fileFormat) {
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.setAttribute('download', `invoices.${fileFormat}`);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function exportXLSXData(data) {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  const blob = new Blob([stringToArrayBuffer(workbookBinary)], { type: 'application/octet-stream' });

  triggerFileDownload(blob, 'xlsx');
}

export function exportCSVData(data) {
  const csvString = Papa.unparse(data);
  const blob = new Blob([csvString], { type: 'text/csv' });

  triggerFileDownload(blob, 'csv');
}
